import React from 'react'
import './home.css'
import Typewriter from 'typewriter-effect'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithubSquare } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

const Home = (props) => {
  return (
    <div className="home">
        <div class="toggle_wrapper">
          {/* <ThemeToggle className="toggle" switchTheme={props.switchTheme} /> */}
        </div>
        <div className="main_text">
          <span className="iam">Hey! I am</span>
          <br />
          <span className="animated_name">Rishi Ravikumar</span>
          <br />
          <br />
          <br />
          <p className='animated_line'>
            <Typewriter className="typewriter"
              options={{
                strings: ['Academically Meritorious',
                 'Effective Communicator', 'Enthusiastic Learner', 'Passionate Programmer',
                'Film Fanatic', 'AI Aficionado'],
                autoStart: true,
                loop: true,
                pauseFor: 1000,
                wrapperClassName: "typewriter",
                cursorClassName: "cursor"
              }}
            />
          </p>
        </div>
        {/* <div className='mouse_container'>
          <div class="mousey">
            <div class="scroller"></div>
          </div>
        </div> */}
        <div class="react-logos">
              <a class="react-logo nav-item nav-link" target="_blank" href="https://www.linkedin.com/in/rishiravikumar/"><h1> <BsLinkedin /> </h1></a>
              <a class="react-logo nav-item nav-link" target="_blank" href="https://github.com/rishi-ravikumar"> <h1> <FaGithubSquare /> </h1> </a>
              <a class="react-logo nav-item nav-link" target="_blank" href="mailto:me@rishiravikumar.com"> <h1> <MdEmail /> </h1> </a>
        </div>
    </div>
  )
}

export default Home