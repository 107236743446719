import React from 'react'
import Toggle from 'react-toggle'
import './themeToggle.css'
import useLocalStorage from 'use-local-storage'

const ThemeToggle = (props) => {

  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  if(defaultDark) {
    var left = '🌑';
    var right = '☀️';
  }
  else {
    var right = '🌑';
    var left = '☀️';
  }


  return (
    <div>
        <p className="toggleSwitch">
        {left}&nbsp;&nbsp;
        <Toggle
          onChange={props.switchTheme}
          icons={false}
        />
        &nbsp;&nbsp;{right}
      </p>
    </div>
  )
}

export default ThemeToggle