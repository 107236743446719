import React, { useState } from "react"
import "./App.css"
import Navbar from './components/Navbar/Navbar'
import Home from './components/Home/Home'
import About from './components/About/About'
import Experience from "./components/Experience/Experience"
import UnderConstruction from './components/UnderConstruction/UnderConstruction'
import Footer from './components/Footer/Footer'

export default function App() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  
  var state = "dark";

  if(defaultDark) {
    state = "dark";
  }
  else {
    state = "light";
  }

  const [theme, setTheme] = useState(state);

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  }

  return (
    <div data-theme={theme} class="maindiv">
      <Navbar switchTheme={switchTheme}/>      
      <Home />
      {/* <About /> */}
      {/* <Experience />
      <UnderConstruction class="under_construction"/> */}
      {/* <Footer /> */}
    </div>
  );
}