import React from 'react'
import './navbar.css'
import LOGO from '../../assets/Logo.png'
import ThemeToggle from '../ThemeToggle/ThemeToggle'

const Navbar = (props) => {
  return (
    <nav class="navbar navbar-expand-lg navbar-light fixed-top">

        <div class="tg_div">
          <ThemeToggle className="toggle" switchTheme={props.switchTheme} />
        </div>

        <a class="navbar-brand main_name" href="">
            <img class="logo" src={LOGO} alt="logo" />
            <span class="remaining_name">ishi Ravikumar</span>
        </a>
        {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            Menu <span class="navbar-toggler-icon"></span>
        </button> */}
        {/* <div class="navbar-collapse" id="navbarNavAltMarkup"> */}
            {/* <div class="navbar-nav ml-auto"> */}
        


        


            {/* <a class="nav-item nav-link active" href="#">Home <span class="sr-only">(current)</span></a> */}
            {/* <a class="nav-item nav-link" href="#about">About</a> */}
            {/* <a class="nav-item nav-link" href="#under_construction">Education</a>
            <a class="nav-item nav-link" href="#under_construction">Experience</a>
            <a class="nav-item nav-link" href="#under_construction">Projects</a>
            <a class="nav-item nav-link" href="#under_construction">Accomplishments</a> */}
            {/* </div> */}
        {/* </div> */}
    </nav>
  )
}

export default Navbar